import { client } from 'app/apollo';
import { CampaignProps } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { TagProps } from 'pages/settings/tags/Tags.consts';
import { tagsGqls } from 'pages/settings/tags/Tags.gqls';
import { clientQuery } from 'utils/api/clientQuery';
import { Tag } from 'utils/types/tags';
import { OfferProps } from 'pages/offers/offerManagement/Offers.const';
import { ImageProps } from 'pages/settings/images/Images.consts';
import { ArchiveStatus } from 'pages/settings/settings.const';

export const createNewTag = (tag: TagProps) => {
  return client.mutate({
    mutation: tagsGqls.mutations.add,
    refetchQueries: ['Tags'],
    variables: {
      data: { ...tag },
    },
  });
};

export const updateTag = async (tag: Partial<TagProps>) => {
  return client.mutate({
    mutation: tagsGqls.mutations.update,
    refetchQueries: ['Tags'],
    variables: {
      data: { ...tag },
    },
  });
};

export const deleteTag = async (tagId: number) => {
  return client.mutate({
    mutation: tagsGqls.mutations.delete,
    refetchQueries: ['Tags'],
    variables: {
      data: { id: Number(tagId) },
    },
  });
};

export const getTagsImpactsById = async (tagId: number) => {
  const tagsImpactsData = await clientQuery(tagsGqls.queries.getById, {
    id: Number(tagId),
  });
  const tagsImpacts = tagsImpactsData.data?.getTag as any;

  type impactType = {
    [key: string]: any;
  };
  const impacts: impactType = {
    offers: {},
    campaigns: {},
    images: {},
  };

  Object.keys(impacts).forEach((impact) => {
    impacts[impact] = tagsImpacts[impact].map((entity: CampaignProps | OfferProps | ImageProps) => {
      let entityStatus;
      if ('status' in entity) {
        entityStatus = entity.status;
      }
      if ('versions' in entity) {
        entityStatus = entity.versions[0].status;
      }
      if ('fileName' in entity) {
        entityStatus = !entity.isArchive ? ArchiveStatus.Unarchived : ArchiveStatus.Archived;
      }
      return {
        id: entity.id,
        ...('externalId' in entity && { externalId: entity.externalId }),
        entityId: ('externalId' in entity && entity.externalId) || entity.id,
        title:
          ('title' in entity && entity.title) ||
          ('name' in entity && entity.name) ||
          ('versions' in entity && entity.versions[0].translations[0].title) ||
          '',
        status: entityStatus,
      };
    });
  });

  return { ...tagsImpacts, ...impacts } as any as Tag;
};
