import { openModal, Modals, closeModal } from 'app/slices/modals';
import { FormMode } from 'utils/types';
import { MessageType } from 'components/shared/notifications/notifications';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import {
  getCampaignsImpactsByTermId,
  getOffersImpactsByTermId,
  getUnarchivedOffers,
  getUnarchivedCampaigns,
  updateTermCondition,
} from 'utils/api/termsConditions';
import { store } from 'app/store';
import { HeaderComponentType } from 'components/impact/impactModal/ImpactModal.consts';
import { termsConditionsPage } from 'app/genericSlices/termsConditions';
import { TermConditionProps } from './TermsConditions.consts';
import { UsageEntityType } from 'app/genericSlices/usage';
import { campaignUsage as campaignUsageSlice, offerUsage as offerUsageSlice } from 'app/genericSlices/usage';
import { inProcessErrorMessage } from 'pages/shared/shared.consts';

const onSaveTermImpact = async (term: TermConditionProps) => {
  try {
    await updateTermCondition(term);
    showToast(MessageType.Success, 'Terms & Conditions updated successfully');
    store.dispatch(closeModal());
    store.dispatch(termsConditionsPage.loadTermsPage());
  } catch (e) {
    if (e.message === inProcessErrorMessage) {
      showToast(MessageType.Error, `T&C cannot be saved at this time as associated campaign approval is in progress`);
      store.dispatch(closeModal());
    } else {
      showToast(
        MessageType.Error,
        `Failed to update terms and conditions${
          e.message.includes('duplication item') ? ` - ${term.name} already exists` : ''
        } `,
      );
    }
  }
};

export const onTermEdit = async (
  termCondition: TermConditionProps,
  editedTerm: TermConditionProps,
  isImpactsEffected: boolean,
) => {
  const [offerImpacts, campaignImpacts] = await Promise.all([
    getOffersImpactsByTermId(termCondition.id, 25),
    getCampaignsImpactsByTermId(termCondition.id, 25),
  ]);
  if ((campaignImpacts.length || offerImpacts.length) && isImpactsEffected) {
    store.dispatch(
      openModal({
        modal: Modals.OldUiUsageModal,
        props: {
          title: 'Save Notification',
          headerComponentType: HeaderComponentType.TermSaveNotificationType,
          submitButtonString: 'Yes, Save',
          haveCampaign: campaignImpacts.length > 0,
          haveOffer: offerImpacts.length > 0,
          entityType: UsageEntityType.TermCondition,
          entityId: termCondition?.id,
          onSubmit: () => onSaveTermImpact(editedTerm),
          onCancel: () => {
            store.dispatch(campaignUsageSlice.actions.resetFilters());
            store.dispatch(offerUsageSlice.actions.resetFilters());
            store.dispatch(closeModal());
          },
        },
      }),
    );
  } else {
    await onSaveTermImpact(editedTerm);
  }
};

const onTermArchiveChange = async (termId: number, isArchive: boolean) => {
  try {
    const archivedTermData: TermConditionProps = { isArchive, id: Number(termId) };
    await updateTermCondition(archivedTermData);
    store.dispatch(closeModal());
    store.dispatch(termsConditionsPage.loadTermsPage());
    showToast(MessageType.Success, `T&C ${isArchive ? 'archived' : 'unarchived'} successfully`);
  } catch (e) {
    showToast(MessageType.Error, `Failed to ${isArchive ? 'archive' : 'unarchive'} T&C`);
  }
};

export const archiveTermClicked = async (term: TermConditionProps, setIsTnCArchieving?: any) => {
  setIsTnCArchieving && setIsTnCArchieving(true);
  const unarchivedOffers = await getUnarchivedOffers(term.id);
  const unarchivedCampaigns = await getUnarchivedCampaigns(term.id);
  const [offerImpacts, campaignImpacts] = await Promise.all([
    getOffersImpactsByTermId(term.id, 25),
    getCampaignsImpactsByTermId(term.id, 25),
  ]);
  if (offerImpacts.length || campaignImpacts.length) {
    const canSave = !unarchivedCampaigns.length && offerImpacts.length && unarchivedOffers.length <= 0;
    store.dispatch(
      openModal({
        modal: Modals.OldUiUsageModal,
        props: {
          title: 'Archive Notification',
          headerComponentType: canSave
            ? HeaderComponentType.TermArchiveNotificationType
            : HeaderComponentType.TermCantArchiveNotificationType,
          haveCampaign: campaignImpacts.length > 0,
          haveOffer: offerImpacts.length > 0,
          entityType: UsageEntityType.TermCondition,
          entityId: Number(term.id),
          onCancel: () => store.dispatch(closeModal()),
          ...(canSave && {
            submitButtonString: 'Yes, Archive',
            onSubmit: () => onTermArchiveChange(term.id, true),
          }),
        },
      }),
    );
    setIsTnCArchieving && setIsTnCArchieving(false);
  } else {
    await onTermArchiveChange(term.id, true);
    setIsTnCArchieving && setIsTnCArchieving(false);
  }
};

export const unarchiveTermClicked = async (term: TermConditionProps) => {
  await onTermArchiveChange(term.id, false);
};

export const openTermModal = (mode: FormMode, termCondition: TermConditionProps, editedTerm?: TermConditionProps) => {
  store.dispatch(
    openModal({
      modal: Modals.TermConditionModal,
      props: {
        mode,
        termCondition,
        editedTerm,
      },
    }),
  );
};
