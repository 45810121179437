/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { Month, Day, WeekDay } from './CalendarMonth.style';


export const CalendarMonth = ({ year, month, onClick, selectedDates = [], disablePast = true, datesToShow, footerRef, setNextFocusableDate
}: any) => {
  const start = new Date(year, month, 0);
  const end = new Date(year, month + 1, 0);
  const dates = [...Array(end.getDate()).keys()].map(() => {
    start.setDate(start.getDate() + 1);
    return new Date(start);
  });
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const dateString = `${start.toLocaleString('default', { month: 'long' })} ${start.getFullYear()}`;

  // event listener for escape press, focus on the footer buttons (cancel/save)
  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && footerRef.current) {
      footerRef.current.focus();
    }
  }

  // on render - add event listener for escape key
  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [])

  const getIsDisabled = (date: any) => {
    return datesToShow?.endDate && datesToShow?.startDate
    ? datesToShow.startDate > date || datesToShow.endDate < date
    : disablePast && date < today

  }
  return (
    <div data-automation-id={`calendar-${dateString}`}>
      <h4>{dateString}</h4>
      <Month>
        <WeekDay>S</WeekDay>
        <WeekDay>M</WeekDay>
        <WeekDay>T</WeekDay>
        <WeekDay>W</WeekDay>
        <WeekDay>T</WeekDay>
        <WeekDay>F</WeekDay>
        <WeekDay>S</WeekDay>
        {dates.map((date) => (
          <Day
            tabIndex={getIsDisabled(date) ? -1 : 0}
            key={date.getTime()}
            role="button"
            checked={selectedDates.find((d: Date) => {
              d.setHours(0, 0, 0, 0);
              return d.getTime() === date.getTime();
            })}
            day={date.getDay() + 1}
            disabled={
              datesToShow?.endDate && datesToShow?.startDate
                ? datesToShow.startDate > date || datesToShow.endDate < date
                : disablePast && date < today
            }
            onClick={() =>
              (datesToShow?.endDate && datesToShow?.startDate
                ? datesToShow.startDate <= date && datesToShow.endDate >= date
                : date >= today) && onClick(date)
            }
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  (datesToShow?.endDate && datesToShow?.startDate
                    ? datesToShow.startDate <= date && datesToShow.endDate >= date
                    : date >= today) && onClick(date)
                }
            }}
          >
            {date.getDate()}
          </Day>
        ))}
      </Month>
    </div>
  );
};
