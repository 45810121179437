import styled from '@emotion/styled';
import { Icon, IconProps } from 'components/shared/icon';
import { appTheme } from 'styles/themes/defaultTheme';
import { NewButtonText } from 'components/shared/button';
import typography from 'styles/typography';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import NewToggleSwitch from 'components/shared/toggleSwitch/NewToggleSwitch';

export const Item = styled.div<{className : string}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  height: ${({className}) => (["products","selected-products"].includes(className) ? '24' : '17')}px;
  [data-title]:after {
    bottom: 25%;
    right: 100%;
  }
`;

export const ViewItem = styled.div<{className : string}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  margin-bottom:15px;
  margin-left:10px;
  height: ${({className}) => (["products","selected-products"].includes(className) ? '24' : '17')}px;
  [data-title]:after {
    bottom: 25%;
    right: 100%;
  }
`;

export const RowContainer = styled.div<{ disabled: boolean; theme?: appTheme }>`
  color: ${({ disabled, theme }) => (disabled ? newGlobalTheme.colors.text.disabled : newGlobalTheme.colors.text.primary)};
  font-size: 12px;
  line-height: 15.17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const StyledIconButtonText = styled(NewButtonText)`
  width: 16px;
  height: 16px;
`;

export const StyledIcon = styled(Icon)<IconProps & { disabled: boolean }>`
  fill: ${({ disabled, theme }) => (disabled ? newGlobalTheme.colors.global.inputDisabled : '#006BAE')};
`;

export const StyledToggleSwitch = styled(NewToggleSwitch)`
  margin-right: 0px;
`;
