import React, { useEffect, useState } from 'react';
import { Actions, TableContainer, StyledHeader, StyledTotalAmountWithTable } from 'pages/shared/shared.style';
import { useSelector } from 'react-redux';
import { tabName } from 'components/header/Header.consts';
import { setFilters, setOrder, locations as locationsSlice } from 'app/slices/locations';
import { store } from 'app/store';
import { Modals, openModal } from 'app/slices/modals';
import { FormMode, OrderDirection, SetOfEntity } from 'utils/types';
import { NewTextPageTitle } from 'components/shared/text/textPageTitle/TextPageTitle';
import PageContainer from 'pages/shared/pageContainer/PageContainer';
import { useUrlFilters } from 'hooks/use-url-filters';
import { GridSortModel } from '@mui/x-data-grid-pro';
import isEqual from 'lodash/isEqual';
import Table from 'components/shared/table/Table';
import { hideActionsCell, showActionsCell } from 'components/shared/table/Table.util';
import { ActionType } from 'components/shared/actionsCell/ActionsCell.consts';
import { LocationSet } from 'utils/types/locations';
import { getLocationSetTableProps } from 'pages/settings/locationSets/LocationSetTableProps';
import { SearchTextField } from 'components/shared/searchTextField/SearchTextField';
import AddNewButton from 'pages/shared/addNewButton/AddNewButton';
import { useQuery } from '@apollo/client';
import { usePersistCaretPosition } from 'hooks/use-persist-caret-position';
import useQueryInterval from 'hooks/use-query-polling';
import { getLocationSetById } from 'utils/api/locationSets';
import { mapOrder } from 'utils/mapping';
import { LocationSetsFilters } from './LocationSets.consts';
import locationSetsGqls from './LocationSets.gqls';
import { getAllItemsRecursively } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.utils';
import { get } from 'lodash';
import { FetchPolicies } from 'utils/types/common';
import { MessageType } from 'components/shared/notifications/notifications';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';

const LocationSets = () => {
  const { order, filters } = useSelector(locationsSlice);
  const [gridSortModel, setGridSortModel] = useState<GridSortModel>(mapOrder(order));
  const { data, fetchMore, loading, error, startPolling, stopPolling } = useQuery(
    locationSetsGqls.queries.getLocationSetsLocationSetsPage,
    {
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
      variables: {
        data: {
          filters: { [LocationSetsFilters.CustomSets]: true, ...filters },
          order,
          limit: 25,
        },
      },
    },
  );

  useEffect(() => {
    if (error) {
      showToast(MessageType.Error, "Error loading location sets");
    }
  }, [error]);

  const {
    getLocationSetsLocationSetsPage: { items: locationSets, total },
  } = getLocationCountData(data);
  useQueryInterval(600000, startPolling, stopPolling);

  const urlFilters = useUrlFilters((params: any) => {
    store.dispatch(
      setFilters(Object.keys(params).reduce((res: any, key: any) => ({ ...res, [key]: params[key] }), {})),
    );
  });

  /**
   * This Method take LocationSet[] as Parameter and we iterate all set and find  location count (totalItems)  using (getAllItemsRecursively) method
   * and set again location count(totalItems) for set.
   * @param {Object} data1:LocationSet[] - The input data to modify and sort.
   * @returns {Object}: { getLocationSetsLocationSetsPage: { items: [], total: 0 } }
   * @constant sortData is used to sort data that means (Location Amount on UI)
   */

  function getLocationCountData(data1: any) {
    const sortData = (order1: 'asc' | 'desc', column: 'name' | 'totalItems', data2: any[]): any[] =>
      [...data2].sort((a, b) => (order1 === 'asc' ? (a[column] < b[column] ? -1 : 1) : a[column] < b[column] ? 1 : -1));

    let modifiedData: any[];
    const locationSet: any[] = get(data1, 'getLocationSetsLocationSetsPage.items');

    if (locationSet) {
      modifiedData = locationSet.map((item: any) => {
        const newItem = { ...item };
        newItem.totalItems = getAllItemsRecursively([item], SetOfEntity.Locations, true).length;
        return newItem;
      });
      if (gridSortModel[0].field === 'totalItems') {
        modifiedData = sortData(gridSortModel[0].sort, 'totalItems', modifiedData);
      }
      return { getLocationSetsLocationSetsPage: { items: modifiedData, total: data1?.getLocationSetsLocationSetsPage?.total } };
    } else {
      return { getLocationSetsLocationSetsPage: { items: [], total: 0 } };
    }
  }

  const [caretPosition, setCaretPosition] = usePersistCaretPosition(filters?.[LocationSetsFilters.SearchQuery] ?? '');

  const openCreateLocationSetModal = () => {
    store.dispatch(
      openModal({
        modal: Modals.LocationSetModal,
        data: {},
        props: { mode: FormMode.New },
      }),
    );
  };

  const onSortModelChange = (sortModel: { [key: string]: OrderDirection }, gridSortModal: GridSortModel) => {
    if (!isEqual(sortModel, order)) {
      store.dispatch(setOrder(sortModel));
      setGridSortModel(gridSortModal);
    }
  };

  const onActionClick = async (actionType: ActionType, set: LocationSet) => {
    let setToDisplay = set;
    if (set.dummy) {
      setToDisplay = await getLocationSetById(set.originalId);
    }

    switch (actionType) {
      case ActionType.Edit:
        store.dispatch(
          openModal({
            modal: Modals.CustomLocationSetModal,
            props: { mode: FormMode.Edit, locationSet: setToDisplay },
          }),
        );
        break;
      case ActionType.View:
        store.dispatch(
          openModal({
            modal: Modals.CustomLocationSetModal,
            props: { mode: FormMode.View, locationSet: setToDisplay },
          }),
        );
        break;
      default:
        break;
    }
  };

  const fetchNextLocationSets = async () => {
    if (locationSets.length < total && !loading) {
      await fetchMore({
        variables: {
          data: {
            filters: { [LocationSetsFilters.CustomSets]: true, ...filters },
            order,
            limit: 25,
            offset: locationSets.length,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (!Object.keys(urlFilters.params).length) {
      urlFilters.filterMulti(filters);
    }
  }, []);

  return (
    <PageContainer>
      <StyledHeader data-automation-id="header">
        <NewTextPageTitle text={tabName.LocationSets} />
        <Actions data-automation-id="actions">
          <SearchTextField
            key={`${JSON.stringify(filters ? filters[LocationSetsFilters.SearchQuery] : '')}_SearchQuery`}
            name="location-sets-search"
            caretPosition={caretPosition}
            value={filters ? filters[LocationSetsFilters.SearchQuery] : ''}
            onChange={(e) => {
              urlFilters.filter('searchQuery', e.target.value);
              setCaretPosition(e);
            }}
          />
          <AddNewButton onClick={openCreateLocationSetModal} />
        </Actions>
      </StyledHeader>
      <TableContainer>
        <StyledTotalAmountWithTable amount={total} />
        <Table
          tableProps={{ ...getLocationSetTableProps(onActionClick), rows: locationSets, loading }}
          onRowOver={(e: React.SyntheticEvent<HTMLDivElement>) => showActionsCell(e.currentTarget.dataset.id)}
          onRowOut={(e: React.SyntheticEvent<HTMLDivElement>) => hideActionsCell(e.currentTarget.dataset.id)}
          onRowsScrollEnd={fetchNextLocationSets}
          getRowId={(row) => row.id}
          onSortModelChange={onSortModelChange}
          gridSortModel={gridSortModel}
        />
      </TableContainer>
    </PageContainer>
  );
};

export default LocationSets;
