import { client } from 'app/apollo';
import offersGqls from 'pages/offers/offerManagement/Offers.gqls';
import { campaignsGqls } from 'pages/campaigns/campaignManagement/Campaigns.gqls';
import { ApprovalStatus, OrderDirection } from 'utils/types';
import { Tag, TagsValidEntities } from 'utils/types/tags';
import { OfferSource, UpdateOfferProps } from 'pages/offers/offerManagement/Offers.const';
import { TagProps } from 'pages/settings/tags/Tags.consts';
import { createNewTag } from 'utils/api/tags';
import { capitalize } from 'utils/text';
import { BaseOffer, BaseOfferTranslation } from '../types/offers';
import { clientQuery } from './clientQuery';
import { MarketConfiguration } from 'app/slices/config';
import { getOfferStatus } from 'utils/offer';
import doeOffersGqls from 'pages/offers/doeOffers/DoeOffers.gqls';

export const createOfferTag = async (name: string) => {
  const tag = {
    name,
    validEntities: [capitalize(TagsValidEntities.Offer)],
    isMasterTag: false,
  } as any as TagProps;
  return createNewTag(tag);
};

export const updateOffer = async (updatedOffer: UpdateOfferProps) => {
  return client.mutate({
    mutation: offersGqls.mutations.update,
    variables: { data: updatedOffer },
    refetchQueries: ['Offers'],
  });
};

export const updateZonesOffers = async (updatedZonesOffers: any) => {
  return client.mutate({
    mutation: offersGqls.mutations.updateLocationSetOffers,
    variables: { data: updatedZonesOffers },
    refetchQueries: ['Offers'],
  });
};

export const setOfferApprovalStatus = async (
  approvalId: number,
  offerId: number,
  offerVersionId: number,
  approvalStatus: ApprovalStatus,
  approver: number,
  comment?: string,
) => {
  if (approvalId) {
    return client.mutate({
      mutation: offersGqls.mutations.updateApproval,
      refetchQueries: ['Offers'],
      variables: {
        data: {
          id: approvalId,
          comment,
          approvalStatus: capitalize(approvalStatus),
          baseOffer: offerId,
          baseOfferVersion: offerVersionId,
        },
      },
    });
  }
  return client.mutate({
    mutation: offersGqls.mutations.createApproval,
    refetchQueries: ['Offers'],
    variables: {
      data: {
        comment,
        approvalStatus: capitalize(approvalStatus),
        baseOfferVersion: offerVersionId,
        baseOffer: offerId,
      },
    },
  });
};

export const createNewOffer = (data: BaseOffer, isDraft: boolean) => {
  if (data.versions[0]?.status) {
    delete data.versions[0].status;
  }
  if (data.versions[0]?.createdBy) {
    delete data.versions[0].createdBy;
  }
  if (data.versions[0]?.updatedBy) {
    delete data.versions[0].updatedBy;
  }
  if (data.versions[0]?.templateValues?.templateType) {
    delete data.versions[0]?.templateValues?.templateType;
  }
  const version: any = {
    ...data.versions[0],
    templateValues: data.versions[0]?.templateValues ?? {},
    isDraft,
    translations: Object.values(data.versions[0].translationsMap)
      .filter((t: BaseOfferTranslation) => t.title !== '' && t.title !== undefined)
      .map((t: BaseOfferTranslation) => {
        // eslint-disable-next-line no-underscore-dangle
        if ((t as any).__typename) {
          // eslint-disable-next-line no-underscore-dangle
          delete (t as any).__typename;
        }
        delete t.createdAt;
        delete t.createdBy;
        delete t.updatedAt;
        delete t.updatedBy;
        delete t.id;
        Object.assign(t, { image: t.image?.id ? Number.parseInt(t.image.id.toString(), 10) : undefined });

        return t;
      }),
    approvals: [],
    translationsMap: undefined,
  };

  if (version.id) {
    delete version.id;
    // eslint-disable-next-line no-underscore-dangle
    delete version.__typename;
  }
  return client.mutate({
    mutation: offersGqls.mutations.add,
    refetchQueries: ['Offers'],
    variables: {
      data: {
        templateType: data.templateType,
        tags: data.tags?.map((val) => Number(val)) ?? [],
        segmentId: data.segmentId,
        version,
      },
    },
  });
};

export const editOffer = async (data: BaseOffer, isDraft: boolean, offerSource?: string) => {
  if (data.versions[0]?.status) {
    delete data.versions[0].status;
  }
  if (data.versions[0]?.createdBy) {
    delete data.versions[0].createdBy;
  }
  if (data.versions[0]?.updatedBy) {
    delete data.versions[0].updatedBy;
  }

  const gqlQueryFromSource = offerSource === 'VCE' ? 'Offers' : 'DoeOffers';

  await client.mutate({
    refetchQueries: [gqlQueryFromSource],
    mutation: offersGqls.mutations.update,
    variables: {
      data: {
        id: data.id,
        version: {
          ...data.versions[0],
          id: undefined,
          __typename: undefined,
          isDraft,
          translations: Object.values(data.versions[0].translationsMap)
            .filter((t: BaseOfferTranslation) => t.title)
            .map((t: BaseOfferTranslation) => ({
              title: t.title,
              subtitle: t.subtitle,
              posTitle: t.posTitle,
              description: t.description,
              language: t.language,
              image: t.image?.id ? Number.parseInt(t.image.id.toString(), 10) : undefined,
            })),
          approvals: [],
          translationsMap: undefined,
        },
        segmentId: data.segmentId,
        tags:
          typeof data.tags[0] === 'object'
            ? data.tags.map((t: Tag) => Number(t.id))
            : data.tags.map((tag) => Number(tag)) ?? [],
      },
    },
  });
};

export const getCampaignsImpactsByOfferId = async (
  baseOfferId: number,
  offerSource: OfferSource,
  limit: number = 9999,
) => {
  const filters = {
    bool: {
      must: [{ match: { 'offerVersion.baseOfferId': baseOfferId } }],
    },
  };

  const campaignImpacts = await clientQuery(campaignsGqls.queries.getForOfferImpact, {
    data: { filters, order: { externalId: OrderDirection.DESC }, limit, offerSource },
  });

  return campaignImpacts.data.getCampaigns.items;
};

export const getOfferImpactsByIds = async (filters: any, config: MarketConfiguration) => {
  const offers = await clientQuery(offersGqls.queries.getAll, {
    data: {
      filters,
      limit: 9999,
      order: {
        id: OrderDirection.DESC,
      },
    },
  });

  return offers.data?.getOffers?.items?.map((item) => ({
    id: item.id,
    title: item.versions[0]?.translationsMap[config.primaryLanguage]?.title,
    status: item.versions[0]?.status,
    defaultTnC: item.versions[0]?.term?.originalTermId ? 'No' : 'Yes',
  }));
};
export const getFilterIdsForOffers = (ids: number[]) => {
  return ids.map((currId) => ({ match: { id: currId } }));
};
export const getOfferForProductChangeDetails = (offers: any, config: MarketConfiguration) => {
  return offers?.map((offer: any) => ({
    id: offer.id,
    title: offer.versions[0]?.translationsMap[config.primaryLanguage]?.title,
    entityId: offer.id,
    status: getOfferStatus(offer),
    isDefault: !offer?.versions[0]?.term?.originalTermId,
  }));
};

export const getOfferById = async (id: number, offerSource?: OfferSource) => {
  let query: any = offersGqls.queries.getById;
  if (offerSource === OfferSource.DOE) {
    query = doeOffersGqls.queries.getById;
  }
  return clientQuery(query, {
    id,
  });
};

export const getVoucherGroupById = async (voucherId: number) =>
  clientQuery(offersGqls.queries.getVoucherGroupById, {
    data: { id: voucherId, forOfferModal: true },
  });
