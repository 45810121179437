import styled from '@emotion/styled';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { StyledWithThemeProps } from 'utils/types';

export const StyledImpactList = styled.div<StyledWithThemeProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const StyledTitleSection = styled.div<StyledWithThemeProps>`
  width: 100%;
`;

export const StyledContainer = styled.div<StyledWithThemeProps>`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const StyledFilterSection = styled.div<StyledWithThemeProps>`
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTitle = styled.h4<StyledWithThemeProps>`
  font-family: Speedee;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  text-transform: capitalize;
  margin: 0;
  color: ${newGlobalTheme.colors.text.primary};
`;

export const StyledImpactCounter = styled.h6<StyledWithThemeProps>`
  font-family: Speedee;
  line-height: 20px;
  margin: 0;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  color: ${newGlobalTheme.colors.text.primary};
  margin-left: 8px;
  min-width: 100px;
`;

export const StyledTableContainer = styled.div`
  height: 255px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 1px 1px 8.3px 1px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  .MuiDataGrid-virtualScroller {
    ::-webkit-scrollbar-thumb {
      background: var(--Grey, #adadad);
      width: 4px;
    }
    ::-webkit-scrollbar {
      width: 4px;
      padding-right: 10px;
    }
  }
  .MuiDataGrid-root {
    border: 0;
  }
  .MuiDataGrid-main {
    color: ${newGlobalTheme.colors.text.primary};
    .MuiDataGrid-columnHeaders {
      font-family: Speedee;
      border-top: 0 !important;
      overflow: initial;
      border-bottom: 3px solid ${newGlobalTheme.colors.status.draft};
      height: 58px;
      div[role='row'] {
        gap: 8px;
        padding: 0px 16px;
      }
      div[role='cell'] {
        width: 130px;
        height: 58px;
      }
    }
    .MuiDataGrid-columnHeader {
      padding: 0px;
      min-width: 130px !important;
      max-width: 130px;
      height: 58px;
    }
    .MuiDataGrid-columnHeaderTitleContainer {
      min-width: 130px !important;
      max-width: 130px;
      min-height: 58px;
      max-height: 58px;
    }
    .MuiDataGrid-cell {
      min-width: 130px !important;
      max-width: 130px;
      min-height: 58px;
      max-height: 58px;
      padding: 0px !important;
    }

    .MuiDataGrid-row {
      font-family: Speedee;
      padding: 0px 16px;
      min-height: 52px !important;
      max-height: 52px !important;
      gap: 8px;
      align-items: center;
      border-bottom: 1px solid ${newGlobalTheme.colors.status.archived};
    }

    .MuiDataGrid-columnHeaderTitleContainer,
    .MuiDataGrid-columnHeaderTitle {
      font-size: 14px;
      padding: 0 !important;
      white-space: initial;
      line-height: 20px;
      text-align: center;
      font-weight: 700 !important;
    }

    .MuiDataGrid-window {
      overflow-x: hidden;
    }

    .MuiDataGrid-cell {
      justify-content: start;
      width: 160px;
      font-size: 14px;
    }

    .MuiDataGrid-iconButtonContainer {
      display: none;
    }
  }
`;

export const SelectboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: 100%;
`;

export const StyledSubTitle = styled.label`
  font-family: Speedee;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
`;
