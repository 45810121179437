import { editOffer, getCampaignsImpactsByOfferId, setOfferApprovalStatus, updateOffer } from 'utils/api/offers';
import { ApprovalStatus } from 'utils/types';
import { BaseOffer } from 'utils/types/offers';
import { removeOfferVersionFromCampaigns } from 'utils/api/campaigns';
import { store } from 'app/store';
import { closeModal, Modals, openModal } from 'app/slices/modals';
import { Impact } from 'components/impact/impactItem/ImpactItem.consts';
import { MessageType } from 'components/shared/notifications/notifications';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { HeaderComponentType, InfoComponentType } from 'components/impact/impactModal/ImpactModal.consts';
import { handleCampaignImpactChange } from 'utils/campaign';
import { CampaignProps } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { isInArray } from 'utils/array';
import { OfferSource, UpdateOfferProps } from '../../Offers.const';
import { UsageEntityType } from 'app/genericSlices/usage';
import { campaignUsage as campaignUsageSlice } from 'app/genericSlices/usage';
import { inProcessErrorMessage } from 'pages/shared/shared.consts';

export const archiveOffer = async (offer: BaseOffer, isArchive: boolean, effectedCampaigns?: CampaignProps[]) => {
  try {
    const archivedOfferData: UpdateOfferProps = { id: offer.id, isArchive };
    await updateOffer(archivedOfferData);
    store.dispatch(closeModal());
    showToast(MessageType.Success, `Offer ${isArchive ? 'archived' : 'unarchived'} successfully`);
  } catch (e) {
    showToast(MessageType.Error, `Failed to ${isArchive ? 'archive' : 'unarchive'} offer`);
  }
};

export const onOfferArchive = async (offer: BaseOffer, setIsOfferArchieving?: any) => {
  setIsOfferArchieving && setIsOfferArchieving(true);
  const campaignImpacts = await getCampaignsImpactsByOfferId(offer.versions[0].baseOfferId, offer.offerSource);
  if (campaignImpacts.length) {
    const canSave = !campaignImpacts.some((impact: Impact) => impact.status !== ApprovalStatus.Archived); // Can save if all Campaigns are Archived
    setIsOfferArchieving && setIsOfferArchieving(false);
    store.dispatch(
      openModal({
        modal: Modals.ImpactModal,
        props: {
          title: 'Archive Notification',
          headerComponentType: !canSave && HeaderComponentType.OfferArchiveNotificationType,
          infoComponentType: canSave && InfoComponentType.OfferSaveNotificationInfoType,
          campaignImpacts: handleCampaignImpactChange(campaignImpacts),
          entityType: 'Offer',
          entityId: offer.id,
          onCancel: () => store.dispatch(closeModal()),
          ...(canSave && {
            submitButtonString: 'Yes, Archive',
            onSubmit: () => archiveOffer(offer, true, canSave && campaignImpacts),
          }),
        },
      }),
    );
  } else {
    await archiveOffer(offer, true);
    setIsOfferArchieving && setIsOfferArchieving(false);
  }
};

export const onOfferViewImpact = async (offer: BaseOffer) => {
  const campaignImpacts = await getCampaignsImpactsByOfferId(offer.versions[0].baseOfferId, offer.offerSource, 25);
  if (campaignImpacts.length) {
    store.dispatch(
      openModal({
        modal: Modals.UsageModal,
        props: {
          title: 'Offer Usage',
          headerComponentType: HeaderComponentType.OfferUsageType,
          haveCampaign: true,
          entityType: UsageEntityType.Offer,
          entityId: offer.id,
          offerVersionId: offer.versions[0].baseOfferId,
          onCancel: () => {
            store.dispatch(closeModal());
            store.dispatch(campaignUsageSlice.actions.resetFilters());
          },
          ...(offer?.offerSource === OfferSource.DOE && { offerSource: offer.offerSource }),
        },
      }),
    );
  } else {
    showToast(MessageType.Info, `The offer is not being used in campaigns`);
  }
};

const rejectOffer = async (offer: BaseOffer, rejectionComment: string) => {
  try {
    await setOfferApprovalStatus(
      offer.versions[0].approvals[0]?.id,
      offer.id,
      offer.versions[0].id,
      ApprovalStatus.Rejected,
      1,
      rejectionComment,
    );
    showToast(MessageType.Success, `Offer rejected successfully`);
    store.dispatch(closeModal());
  } catch (e) {
    if (e.message === inProcessErrorMessage) {
      showToast(
        MessageType.Error,
        `Offer cannot be modified at this time as associated campaign approval is in progress`,
      );
      store.dispatch(closeModal());
    } else {
      showToast(MessageType.Error, `Failed to reject offer`);
    }
  }
};

export const onOfferReject = async (offer: BaseOffer, rejectionComment: string) => {
  const campaignImpacts = await getCampaignsImpactsByOfferId(offer.versions[0].baseOfferId, offer.offerSource, 25);
  if (campaignImpacts.length) {
    store.dispatch(
      openModal({
        modal: Modals.UsageModal,
        props: {
          title: 'Reject Notification',
          headerComponentType: HeaderComponentType.OfferRejectNotificationType,
          submitButtonString: 'Yes, Reject',
          onSubmit: async () => {
            await rejectOffer(offer, rejectionComment);
          },
          entityType: UsageEntityType.Offer,
          entityId: offer.id,
          haveCampaign: true,
          offerVersionId: offer.versions[0].baseOfferId,
          onCancel: () => {
            store.dispatch(closeModal());
            store.dispatch(campaignUsageSlice.actions.resetFilters());
          },
        },
      }),
    );
  } else {
    await rejectOffer(offer, rejectionComment);
  }
};

const onEditOffer = async (offer: BaseOffer, formData: any, isDraft: boolean, offerSource?: string) => {
  await editOffer(formData, isDraft, offerSource);
  showToast(MessageType.Success, `Offer updated successfully`);
  store.dispatch(closeModal());
};

const notifyOnImpactedCampaigns = (campaignImpacts: any[], offer: BaseOffer, formData: any, isDraft: boolean, offerSource?: string) => {
  const isSegmentIdEdited = offer.segmentId !== formData.segmentId;
  const activeDeployedCampaigns = campaignImpacts.filter((campaign: CampaignProps) =>
    isInArray([ApprovalStatus.Active, ApprovalStatus.Deployed, ApprovalStatus.AssociationStopped], campaign?.status),
  );
  if (isSegmentIdEdited && activeDeployedCampaigns?.length) {
    store.dispatch(
      openModal({
        modal: Modals.ImpactModal,
        props: {
          title: 'Offer Notification',
          headerComponentType: HeaderComponentType.OfferEditSegmentIdNotification,
          campaignImpacts: handleCampaignImpactChange(activeDeployedCampaigns),
          entityType: 'Offer',
          entityId: offer.id,
          onCancel: () => store.dispatch(closeModal()),
        },
      }),
    );
    return;
  }

  const infoComponentProps =
    offer?.offerSource === OfferSource.VCE
      ? { infoComponentType: InfoComponentType.OfferSaveNotificationInfoType }
      : {};

  store.dispatch(
    openModal({
      modal: Modals.ImpactModal,
      props: {
        title: 'Save Notification',
        headerComponentType: HeaderComponentType.OfferSaveNotificationHeaderType,
        ...infoComponentProps,
        submitButtonString: 'Yes, Save',
        onSubmit: async () => {
          await onEditOffer(offer, formData, isDraft, offerSource);
        },
        campaignImpacts: handleCampaignImpactChange(campaignImpacts),
        entityType: 'Offer',
        entityId: offer.id,
        onCancel: () => store.dispatch(closeModal()),
      },
    }),
  );
};

export const onOfferEdit = async (offer: BaseOffer, formData: any, isDraft: boolean, offerSource: string) => {
  const campaignImpacts = await getCampaignsImpactsByOfferId(offer.versions[0].baseOfferId, offer.offerSource);
  if (campaignImpacts.length) {
    notifyOnImpactedCampaigns(campaignImpacts, offer, formData, isDraft, offerSource);
  } else {
    await onEditOffer(offer, formData, isDraft, offerSource);
  }
};
