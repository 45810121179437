import { client } from 'app/apollo';
import { ApprovalStatus } from 'utils/types';
import { capitalize } from 'utils/text';
import { campaignsGqls } from 'pages/campaigns/campaignManagement/Campaigns.gqls';
import {
  BulkCampaignsStatusUpdateDto,
  CampaignCreationProps,
  CampaignProps,
  CampaignReportProps,
} from 'pages/campaigns/campaignManagement/Campaigns.consts';
import {
  convertDateToUtcAsIsoString,
  convertDateToUtcAsIsoStringUsingLibrary,
  formatTimeToHoursMinutes,
} from 'utils/date';
import pick from 'lodash/pick';
import { isObject, removeEmpty } from 'utils/object';
import { createNewTag } from 'utils/api/tags';
import { TagsValidEntities } from 'utils/types/tags';
import { TagProps } from 'pages/settings/tags/Tags.consts';
import { clientQuery } from 'utils/api/clientQuery';
import { CampaignType } from 'utils/types/campaigns';
import { SelectedCampaignProps } from 'pages/reports/redemptions/Redemptions.consts';
import { ImpactFilters, ImpactFiltersEum } from 'app/genericSlices/impacts';
import offersGqls from 'pages/offers/offerManagement/Offers.gqls';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';
import { useHistory } from 'react-router-dom';

export const prepareNewCampaignForSave = (
  campaign: CampaignCreationProps,
  isDraft: boolean,
  startTimezone: string,
  endTimezone: string,
  isBulk = false,
  isExternalVoucher = false,
): CampaignCreationProps | CampaignProps => {
  const campaignToSave = {
    ...pick(campaign, ['title', 'description', ...(campaign.type === CampaignType.Voucher ? ['voucherConfig'] : [])]),
    isDraft,
    type: capitalize(campaign.type),
    restaurantEligibility: getCampaignRestaurantEligibility(campaign, isExternalVoucher),
    baseOfferVersionId: campaign.offerVersion.id,
    isTriggerEvent: campaign.isTriggerEvent,
    schedule: getCampaignSchedule(campaign, isBulk, isExternalVoucher, startTimezone, endTimezone),
    tags: campaign.tags?.map((tag) => (tag && isObject(tag) ? Number(tag.id) : Number(tag))) || [],
    isPriority: campaign?.isPriority,
    isTopPriority: campaign?.isTopPriority,
    ...(campaign.localSchedule?.period
      ? {
          localSchedule: {
            ...(isBulk
              ? {}
              : {
                  periodId: isObject(campaign.localSchedule.period)
                    ? Number(campaign.localSchedule.period.id)
                    : Number(campaign.localSchedule.period),
                }),
            zoneId: campaign.localSchedule.zone
              ? isObject(campaign.localSchedule.zone)
                ? Number(campaign.localSchedule.zone.id)
                : Number(campaign.localSchedule.zone)
              : campaign.localSchedule.zone,
          },
        }
      : localScheduleConditionHandler(campaign)),
    push_notification: {
      startDate: campaign?.push_notification?.startDate
        ? convertDateToUtcAsIsoString(campaign?.push_notification?.startDate, startTimezone)
        : '',
      push_notification_translation: campaign?.push_notification?.push_notification_translation,
    },
  };
  return campaignToSave as any as CampaignProps;
};

const getCampaignRestaurantEligibility = (campaign: CampaignCreationProps, isExternalVoucher: boolean) => {
  if (isExternalVoucher) {
    return {
      restaurantGroups: [],
      restaurants: [],
      excludeRestaurantGroups: [],
      excludeRestaurants: [],
      isNationwide: true,
    };
  }

  return {
    restaurantGroups: (campaign?.restaurantEligibility?.restaurantGroups || []).map((rGroup) => ({
      id: rGroup.id,
      type: rGroup.type,
    })),
    restaurants: campaign?.restaurantEligibility?.restaurants || [],
    excludeRestaurantGroups: (campaign?.restaurantEligibility?.excludeRestaurantGroups || []).map((rGroup) => ({
      id: rGroup.id,
      type: rGroup.type,
    })),
    excludeRestaurants: campaign?.restaurantEligibility?.excludeRestaurants || [],
    isNationwide: campaign?.restaurantEligibility?.isNationwide === true,
  };
};

const getCampaignSchedule = (
  campaign: CampaignCreationProps,
  isBulk: boolean,
  isExternalVoucher: boolean,
  startTimezone?: string,
  endTimezone?: string,
) => {
  return {
    ...removeEmpty(campaign.schedule),
    ...(Boolean(campaign.schedule.recurrence.length) && {
      recurrence: campaign.schedule.recurrence.map((recurrence) => ({
        ...recurrence,
        redemptionNumber: Number(recurrence.redemptionNumber),
      })),
    }),
    validityStartTime: formatTimeToHoursMinutes(campaign.schedule.validityStartTime),
    validityEndTime: formatTimeToHoursMinutes(campaign.schedule.validityEndTime),
    ...(isBulk
      ? {
          dates: campaign.schedule.dates.map(
            (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
          ),
          duration: Number(campaign.schedule.duration),
        }
      : {
          campaignStart: convertDateToUtcAsIsoStringUsingLibrary(campaign.schedule.campaignStart, startTimezone),
          campaignEnd: convertDateToUtcAsIsoStringUsingLibrary(campaign.schedule.campaignEnd, endTimezone),
        }),
    ...(isExternalVoucher
      ? {
          exchangeLimit: {
            globalLimit: Number(campaign?.schedule?.exchangeLimit?.globalLimit),
            customerLifetimeLimit: Number(campaign?.schedule?.exchangeLimit?.customerLifetimeLimit),
          },
        }
      : {}),
  };
};

const localScheduleConditionHandler = (campaign: any) => {
  if (campaign.localSchedule?.zone) {
    return {
      localSchedule: {
        zoneId: isObject(campaign.localSchedule.zone)
          ? campaign.localSchedule.zone.id
          : Number(campaign.localSchedule.zone),
      },
    };
  } else if (campaign.localSchedule && campaign.isLocalCampaign) {
    return {
      localSchedule: {
        periodId: null,
        zoneId: null,
      },
    };
  } else {
    return {};
  }
};

export const prepareCampaignForUpdate = (
  campaign: CampaignProps,
  isDraft: boolean,
  startTimezone: string,
  endTimezone: string,
  isExternalVoucher = false,
): Partial<CampaignProps> => {
  const campaignChanges = prepareNewCampaignForSave(
    campaign,
    isDraft,
    startTimezone,
    endTimezone,
    false,
    isExternalVoucher,
  ) as any as CampaignProps;
  const campaignToSave = {
    ...campaignChanges,
    id: campaign.id,
    externalId: campaign.externalId,
    schedule: {
      ...campaignChanges.schedule,
      ...(Boolean(campaignChanges.schedule.recurrence.length) && {
        recurrence: campaignChanges.schedule.recurrence.map((recurrence) => ({
          ...recurrence,
          redemptionNumber: Number(recurrence.redemptionNumber),
        })),
      }),
    },
    ...(campaign.localSchedule?.period
      ? {
          localSchedule: {
            periodId: isObject(campaign.localSchedule.period)
              ? campaign.localSchedule.period.id
              : campaign.localSchedule.period,
            zoneId: campaign.localSchedule.zone
              ? isObject(campaign.localSchedule.zone)
                ? Number(campaign.localSchedule.zone.id)
                : Number(campaign.localSchedule.zone)
              : campaign.localSchedule.zone,
          },
        }
      : {}),
  };

  return campaignToSave as CampaignProps;
};

export const createCampaignTag = async (name: string) => {
  const tag = {
    name,
    validEntities: [capitalize(TagsValidEntities.Campaign)],
    isMasterTag: false,
  } as any as TagProps;
  return createNewTag(tag);
};

export const createNewCampaign = (campaign: CampaignProps) => {
  return client.mutate({
    mutation: campaignsGqls.mutations.add,
    variables: {
      data: { ...campaign },
    },
    refetchQueries: ['Campaigns', 'GetCampaignsQuickFilters'],
  });
};

export const createNewCampaigns = (campaign: CampaignCreationProps) => {
  return client.mutate({
    mutation: campaignsGqls.mutations.addBulk,
    variables: {
      data: { ...campaign },
    },
    refetchQueries: (result) => {
      return result?.data?.createCampaigns?.isProcessed ? ['Campaigns', 'GetCampaignsQuickFilters'] : [];
    },
  });
};

export const updateCampaign = async (campaign: Partial<CampaignProps>, offerSource?: string) => {
  const gqlQueryFromSource = offerSource === OfferSource.DOE ? 'DoeCampaigns' : 'Campaigns';
  return client.mutate({
    mutation: campaignsGqls.mutations.update,
    variables: {
      data: { ...campaign },
    },
    refetchQueries: [gqlQueryFromSource, 'GetCampaignsQuickFilters'],
  });
};

export const setCampaignApprovalStatus = async (
  campaignId: number,
  approvalStatus: ApprovalStatus,
  approvalId?: number,
  comment?: string,
) => {
  return client.mutate({
    mutation: campaignsGqls.mutations.updateApproval,
    variables: {
      data: {
        id: campaignId,
        approval: {
          id: Number(approvalId),
          approvalStatus: capitalize(approvalStatus),
          comment,
        },
        isDraft: false,
      },
    },
    refetchQueries: ['Campaigns', 'GetCampaignsQuickFilters'],
  });
};

export const bulkCampaignsStatusUpdate = async (
  bulkCampaignsStatusUpdateDto: BulkCampaignsStatusUpdateDto,
): Promise<number[]> => {
  const result = await client.mutate<number[]>({
    mutation: campaignsGqls.mutations.bulkCampaignsStatusUpdate,
    variables: {
      data: {
        ...bulkCampaignsStatusUpdateDto,
      },
    },
    refetchQueries: ['Campaigns', 'GetCampaignsQuickFilters'],
  });
  return result.data;
};

export const deployCampaignById = async (campaignId: number) => {
  return client.mutate({
    mutation: campaignsGqls.mutations.deployCampaign,
    variables: {
      data: {
        id: campaignId,
      },
    },
    refetchQueries: ['Campaigns', 'GetCampaignsQuickFilters'],
  });
};

export const reDeployCampaignById = async (campaignId: number, approvalId: number) => {
  return client.mutate({
    mutation: campaignsGqls.mutations.reDeployCampaign,
    variables: {
      data: {
        id: campaignId,
        approval: {
          id: Number(approvalId),
          approvalStatus: capitalize(ApprovalStatus.Deployed),
        },
        isDraft: false,
      },
    },
    refetchQueries: ['Campaigns', 'GetCampaignsQuickFilters'],
  });
};

export const removeOfferVersionFromCampaigns = async (data: Record<string, number[]>) => {
  return client.mutate({
    mutation: campaignsGqls.mutations.removeOfferVersionFromCampaigns,
    variables: {
      data: { campaignIds: data.campaignIds },
    },
    refetchQueries: ['Campaigns', 'GetCampaignsQuickFilters'],
  });
};

export const getCampaignImpactsByIds = async (filters: ImpactFilters) => {
  const campaigns = await clientQuery(campaignsGqls.queries.getImpactedByJobUpdate, {
    data: { filters: { ids: filters[ImpactFiltersEum.Id] }, limit: 9999 },
  });
  let selectedStatus = filters[ImpactFiltersEum.Status];
  if (selectedStatus.includes(ApprovalStatus.Active)) {
    selectedStatus = [...selectedStatus, ApprovalStatus.AssociationStopped];
  }
  return campaigns.data?.getImpactedByJobUpdate?.items?.reduce((result, item) => {
    if (selectedStatus.length === 0 || selectedStatus.includes(item.status)) {
      result.push({
        id: item.externalId,
        title: item.title,
        status: item.status,
      });
    }
    return result;
  }, []);
};

export const getCampaignsForReport = async (fetchData: any) => {
  const {
    data: {
      getCampaignsReport: { items },
    },
  } = await clientQuery(campaignsGqls.queries.getCampaingsForReport, {
    data: fetchData,
  });
  return items as CampaignReportProps[];
};

export const getCampaignSelectionDataByExternalIds = async (externalIds: number[]) => {
  const filterIds = externalIds.map((currId) => ({ match: { externalId: currId } }));
  const campaigns = await clientQuery(campaignsGqls.queries.getAll, {
    data: { filters: { bool: { must: [{ bool: { should: filterIds } }] } } }
  });

  return campaigns.data?.getCampaigns?.items?.map((item) => ({
    id: item.id,
    externalId: item.externalId,
    title: item.title,
    status: item.status,
  })) as SelectedCampaignProps[];
};

export const getCampaignById = async (campaignId: number) =>
  clientQuery(campaignsGqls.queries.getById, {
    id: campaignId,
  });

export const getVoucherGroupById = async (voucherId: number) =>
  clientQuery(offersGqls.queries.getVoucherGroupById, {
    data: { id: voucherId },
  });

export const getFilterIdsForCampaigns = (ids: number[]) => {
  return ids.map((currId) => ({ match: { id: currId } }));
};

export const getCampaignForProductChangeDetails = (campaigns: any) => {
  return campaigns?.map((campaign: any) => ({
    id: campaign.id,
    title: campaign.title,
    entityId: campaign.externalId,
    status: campaign.status === ApprovalStatus.AssociationStopped ? ApprovalStatus.Active : campaign.status,
  }));
};
