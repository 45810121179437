import styled from '@emotion/styled';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import zIndex from 'styles/zIndex';
import ButtonBase, { NewButtonBase } from '../buttonBase/ButtonBase';

export const StyledButtonBase = styled(ButtonBase)`
  color: ${({ theme }) => theme.colors.text.white};
  background-color: ${({ theme }) => theme.colors.global.inputPrimary};
  border: none;
  height: 32px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.button.hover};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.button.disabled};
  }
`;

export const StyledButtonText = styled(ButtonBase)`
  border: none;
  background-color:${newGlobalTheme.colors.global.background}
  &:hover {
  border:none;
    background-color: ${newGlobalTheme.colors.global.background};
  }
`;

export const StyledButtonBaseSave = styled(ButtonBase)`
  width: 106px;
  height: 32px;
  padding: 8px 12px 8px 12px;
  gap: 4px;
  border-radius: 5px 5px 5px 5px;
  border: 1px solid ${newGlobalTheme.colors.global.borderColorboxes};
  opacity: 0px;
  background-color: ${newGlobalTheme.colors.global.selectedBackground};
  font-size: 14px;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  color: ${newGlobalTheme.colors.global.primaryTextColor};
  &:disabled {
    border: 1px solid ${newGlobalTheme.colors.global.borderColor};
    color: ${newGlobalTheme.colors.global.borderColor};
  }
  &:focus-visible {
  outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
  outline-offset: 2px;
  z-index: ${zIndex.tabNavigation};
  transition: none;
  }
`;

export const StyledButtonBaseStopAssociation = styled(StyledButtonBaseSave)`
  width: 129px;
  flex-grow : 1;
`;

export const StyledButtonBaseCancel = styled(ButtonBase)`
  width: 90px;
  height: 32px;
  font-family: Speedee;
  padding: 8px 12px 8px 12px;
  gap: 4px;
  border-radius: 5px 5px 5px 5px;
  border: 1px solid ${newGlobalTheme.colors.global.borderColorboxes};
  opacity: 0px;
  background-color: ${newGlobalTheme.colors.global.selectedBackground};
  font-size: 14px;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  color: ${newGlobalTheme.colors.global.primaryTextColor};
  &:disabled {
    border: 1px solid ${newGlobalTheme.colors.global.borderColor};
    color: ${newGlobalTheme.colors.global.borderColor};
  }
`;

export const StyledButtonBaseReject = styled(ButtonBase)`
  width: 100px;
  height: 32px;
  padding: 8px 12px 8px 12px;
  gap: 4px;
  border-radius: 5px 5px 5px 5px;
  border: 1px solid ${newGlobalTheme.colors.global.borderColorboxes};
  opacity: 0px;
  background-color: ${newGlobalTheme.colors.global.selectedBackground};
  font-size: 14px;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  color: ${newGlobalTheme.colors.global.primaryTextColor};
  &:disabled {
    border: 1px solid ${newGlobalTheme.colors.global.borderColor};
    color: ${newGlobalTheme.colors.global.borderColor};
  }
  &:focus-visible {
  outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
  outline-offset: 2px;
  z-index: ${zIndex.tabNavigation};
  transition: none;
  }
`;

export const StyledButtonBaseSaveAs = styled(ButtonBase)`
  width: 205px;
  height: 32px;
  padding: 8px 12px 8px 12px;
  gap: 4px;
  border-radius: 5px 5px 5px 5px;
  border: 1px solid ${newGlobalTheme.colors.button.border.secondary};
  opacity: 0px;
  background-color: ${newGlobalTheme.colors.global.brandMain};
  font-size: 14px;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  color: ${newGlobalTheme.colors.global.primaryTextColor};
  &:disabled {
  background-color: ${newGlobalTheme.colors.global.disabledGold};
  border:none;
  color: ${newGlobalTheme.colors.global.borderColorboxes};
  }
  &:focus-visible {
  outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
  outline-offset: 2px;
  z-index: ${zIndex.tabNavigation};
  transition: none;
  }
`;


export const StyledButtonBaseApprove = styled(ButtonBase)`
  width: 100px;
  height: 32px;
  padding: 8px 12px 8px 12px;
  gap: 4px;
  border-radius: 5px 5px 5px 5px;
  border: 1px solid ${newGlobalTheme.colors.button.border.secondary};
  opacity: 0px;
  background-color: ${newGlobalTheme.colors.global.brandMain};
  font-size: 14px;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  color: ${newGlobalTheme.colors.global.primaryTextColor};
  &:disabled {
  background-color: ${newGlobalTheme.colors.global.disabledGold};
  border:none;
  color: ${newGlobalTheme.colors.global.borderColorboxes};
  }
  &:focus-visible {
  outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
  outline-offset: 2px;
  z-index: ${zIndex.tabNavigation};
  transition: none;
  }
`;

export const StyledBaseSave = styled(ButtonBase)`
  width: 100px;
  height: 32px;
  font-family: Speedee;
  padding: 8px 12px 8px 12px;
  gap: 4px;
  border-radius: 5px 5px 5px 5px;
  border: 1px solid ${newGlobalTheme.colors.button.border.secondary};
  opacity: 0px;
  background-color: ${newGlobalTheme.colors.global.brandMain};
  font-size: 14px;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  color: ${newGlobalTheme.colors.global.primaryTextColor};
  &:disabled {
  background-color: ${newGlobalTheme.colors.global.disabledGold};
  border:none;
  color: ${newGlobalTheme.colors.global.borderColorboxes};
  }
  &:focus-visible {
  outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
  outline-offset: 2px;
  z-index: ${zIndex.tabNavigation};
  transition: none;
  }
`;

export const StyledNewButtonBase = styled(NewButtonBase)`
  color: ${({ theme }) => theme.colors.text.white};
  background-color: ${newGlobalTheme.colors.button.primary};
  border: none;
  height: 60px;

  &:focus-visible {
    outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
    outline-offset: 4px;
    z-index: ${zIndex.tabNavigation};
    transition: none;
  }

  &:hover {
    background-color: ${newGlobalTheme.colors.button.hover};
  }

  &:disabled {
    background-color: ${newGlobalTheme.colors.button.disabled};
  }
`;

export const StyledAddNewButtonBase = styled(NewButtonBase)`
  background-color: ${newGlobalTheme.colors.button.new.primary};
  border: 1.26px solid ${newGlobalTheme.colors.button.new.border};
  height: 32px;
  font-size: 14px;

  &:hover {
    border-color: ${newGlobalTheme.colors.global.tabNavigation};
  }

  &:focus-visible {
    outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
    z-index: ${zIndex.tabNavigation};
    transition: none;
  }
  
  &:disabled {
    color: ${newGlobalTheme.colors.text.disabledSave};
    background-color: ${newGlobalTheme.colors.global.disabledGold};
    border: none;
  }
`;
