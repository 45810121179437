import styled from '@emotion/styled/macro';
import { Icon, IconProps } from 'components/shared/icon';
import { ButtonContained } from 'components/shared/button';
import { Loader } from 'components/shared/loader';
import { defaultTheme } from 'styles/themes/defaultTheme';
import typography, { newTypography } from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import TotalAmount from 'components/shared/totalAmount/TotalAmount';
import { ThemeProps } from '../settings/termsConditions/TermsConditions.consts';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { NewButtonBase } from 'components/shared/button/buttonBase/ButtonBase';
import zIndex from 'styles/zIndex';
import { SelectboxPaginate } from 'components/shared/selectboxPaginate/SelectboxPaginate';

export const StyledHeader = styled.header<ThemeProps>`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.global.border};
  padding: ${typography.unit * 16.25}px ${typography.unit * 6}px ${typography.unit * 5}px ${typography.unit * 6}px;
`;

export const NewStyledHeader = styled.header<ThemeProps>`
  display: flex;
  flex-direction: column;
  padding: ${typography.unit * 16.25}px ${typography.unit * 6}px 0 ${typography.unit * 6}px;
`;

export const Actions = styled.div<ThemeProps>`
  display: flex;
  justify-content: space-between;
`;

export const FilterIcon = styled(Icon)<IconProps>`
  width: 13px;
  fill: ${({ theme }) => theme.colors.text.secondary};
  margin-right: ${typography.unit * 4}px;
`;

export const StyledLoader = styled(Loader)`
  margin: auto;
  display: flex;
`;

export const StyledSearch = styled(Icon)<IconProps>`
  fill: ${defaultTheme.colors.global.border};
  width: 16px;
`;

export const ColoredButtonBase = styled(ButtonContained)`
  color: ${({ theme }) => theme.colors.text.primary};

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

export const NewStyledApproveButton = styled(NewButtonBase)`
  background-color: ${newGlobalTheme.colors.button.new.primary};
  border: 1.26px solid ${newGlobalTheme.colors.button.new.border};
  height: 32px;
  font-size: 14px;

  &:hover {
    border-color: ${newGlobalTheme.colors.global.tabNavigation};
  }

  &:focus-visible {
    outline: 1px solid ${newGlobalTheme.colors.global.tabNavigation};
    z-index: ${zIndex.tabNavigation};
    transition: none;
  }

  &:disabled {
    color: ${newGlobalTheme.colors.text.disabledSave};
    background-color: ${newGlobalTheme.colors.global.disabledGold};
    border: none;
  }
`;

export const StyledApproveButton = styled(ColoredButtonBase)`
  background-color: ${({ theme }) => theme.colors.button.approve.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.button.approve.hover};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.text.disabled};
    background-color: ${({ theme }) => theme.colors.button.approve.disabled};
  }
`;

export const StyledRejectButton = styled(ColoredButtonBase)`
  background-color: ${({ theme }) => theme.colors.button.reject.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.button.reject.hover};
  }
`;

export const StyledRevokeButton = styled(ColoredButtonBase)`
  background-color: ${({ theme }) => theme.colors.button.revoke.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.button.revoke.hover};
  }
`;

export const StyledFormSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
`;

export const StyledSectionTitle = styled.div<StyledWithThemeProps & {section?: string}>`
    font-size: 20px;
    font-weight: 700;
    line-height: 22.83px;
    margin-bottom:8px;
    width:530px;
    letter-spacing: -0.03em;
    text-align: left;
    font-family: Speedee; 
    color: ${newGlobalTheme.colors.text.primary}; 

    ${( { section } ) => section && section === 'locations' && 'padding-bottom: 0px; margin-bottom: 0px;'}
    ${( { section } ) => section && section === 'schedule' && 'padding-bottom: 0px;'}
    ${( { section } ) => section && section === 'generalDetails' || section === 'schedule' ? 'height: 28px' : 'height: 23px;'}

`;

export const OfferFormStyledSectionTitle = styled.div<StyledWithThemeProps>`
  color: ${newGlobalTheme.colors.global.primaryTextColor}; 
  font-family: Speedee;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.834px;
  letter-spacing: -0.6px;
  height: 28px;
  align-self: stretch;
`

export const NewTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 0 0 8px 8px;
  background-color: ${newGlobalTheme.colors.global.selectBackground};
  margin: 0 ${typography.unit * 6}px ${typography.unit * 4}px ${typography.unit * 6}px;

  .MuiDataGrid-root {    
    .MuiDataGrid-main,
    .MuiDataGrid-columnHeader,
    .MuiDataGrid-columnHeaderTitleContainer {
      background: #fff;
    },
    .MuiDataGrid-cell {
      padding-right: 0 !important;
      justify-content: start !important;
      border-bottom: 3px solid #F1F2F3;
      min-height: 45px !important;
      max-height: 45px !important;
    }

  }
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${typography.unit * 4}px ${typography.unit * 6}px;
  height: 100%;

  .MuiDataGrid-root {
    border-right-width: 0;
    border-left-width: 0;
    border-bottom-width: 0;

    .MuiDataGrid-main,
    .MuiDataGrid-columnHeader,
    .MuiDataGrid-columnHeaderTitleContainer,
    .MuiDataGrid-cell {
      padding-left: 0 !important;
      justify-content: start !important;
    }
  }
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: ${typography.unit * 5}px;
  flex: 1;
`;

export const StyledFilterBarSelect = styled(Selectbox)`
  margin-right: ${typography.unit * 7}px;
` as unknown as typeof Selectbox;

export const NewStyledFilterBarSelect = styled(NewSelectbox)`
  margin: ${newTypography.unit * 1}px ${newTypography.unit * 10}px;
` as unknown as typeof Selectbox;

const StyledTotalAmount = styled(TotalAmount)`
  align-self: flex-end;
`;

export const StyledTotalAmountWithTable = styled(StyledTotalAmount)`
  margin-bottom: ${typography.unit * 4}px;
`;

export const StyledTotalAmountWithGrid = styled(StyledTotalAmount)`
  padding: ${typography.unit * 4}px ${typography.unit * 7}px;
`;

export const NewStyledTotalAmountWithTable = styled(StyledTotalAmount)`
  margin-right: ${typography.unit * 4}px;
  font-family: ${newTypography.primaryFont};
  font-style: italic;
  font-size: 12px;
  color: ${newGlobalTheme.colors.global.primaryTextColor};
`;

export const StyledFilterBarSelectPaginate = styled(SelectboxPaginate)`
  margin-right: ${typography.unit * 7}px;
` as unknown as typeof SelectboxPaginate;
