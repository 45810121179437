import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { calcProductsHint } from 'utils/product';
import { GenericEntity, OrderDirection } from 'utils/types';
import { useQuery } from '@apollo/client';
import productSetsGqls from 'pages/settings/productSets/ProductSets.gqls';
import { Product } from 'utils/types/products';
import { TemplateProps } from '../Template.consts';
import { ProductPicker, StyledError, StyledForSelectbox, StyledLabel } from '../Template.style';
import getProductsAction from '../shared/ProductsActionCondition';
import { FetchPolicies } from 'utils/types/common';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';
import { LineWrapper, OfferInfoWrapper, ProductLine } from '../../../OfferForm.style';
import { store } from 'app/store';
import { setData } from 'app/slices/modals';

export const Substitution = ({ disabled, onProductSelection, offerSource, mode, offerID, onProductPreviewClick }: TemplateProps) => {
  const {
    register,
    control,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();

  const [product] = watch(['versions.0.templateValues.product']);

  const substitutes = useWatch({ control, name: 'versions.0.templateValues.substitutes' });
  const { data: productsData } = useQuery<{ getProducts: GenericEntity<Product> }>(
    productSetsGqls.queries.getAllProducts,
    {
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
      variables: {
        data: {
          filters: {},
          order: { name: OrderDirection.ASC },
          limit: 20000,
        },
      },
    },
  );

  // On substitution for value change, update isFormDirty and isSubstitutionProductInvalid value
  // isSubstitutionProductInvalid is to track whether templateValues.product is invalid or empty
  const handleSelectChange = ( value : any ) => {
      store.dispatch(
        setData({
          data: {
            isFormDirty: true,
          },
        }),
      );
      if (value === null) {
        store.dispatch(
          setData({
            data: {
              isSubstitutionProductInvalid: true,
            },
          }),
        );
      }
      else {
        store.dispatch(
          setData({
            data: {
              isSubstitutionProductInvalid: false,
            },
          }),
        );
      }
  };

  return (
    <OfferInfoWrapper disabled={disabled}>
      <LineWrapper gap={8}>
        <StyledLabel>Buy</StyledLabel>
        <ProductPicker>
          <span>1</span>
          <ProductLine>
          {calcProductsHint(substitutes)}
          </ProductLine>
          {getProductsAction(
            disabled,
            substitutes,
            getValues,
            'Select Products',
            'substitutes',
            (data: any) => ({
              substitutes: data,
            }),
            onProductSelection,
            null,
            null,
            null,
            offerID,
            onProductPreviewClick
          )}
          <input
            type="hidden"
            {...register('versions.0.templateValues.substitutes', {
              value: substitutes,
              shouldUnregister: true,
              required: true,
            })}
          />
        </ProductPicker>
      </LineWrapper>
      <LineWrapper gap={8}>
        <StyledLabel>For</StyledLabel>
        <ProductPicker>
          <span>the price of</span>
          <StyledForSelectbox
            key={`products_${productsData?.getProducts?.items.length}`}
            control={control}
            errors={errors}
            name="versions.0.templateValues.product"
            placeholder="Select Product"
            disabled={disabled || (offerSource === OfferSource.DOE && mode === 'edit')}
            items={productsData?.getProducts?.items.map((item) => ({
              id: item.plu,
              name: `${item?.plu} - ${item?.name}`,
            }))}
            validation={{ required: true }}
            withSearch
            reset
            selectWidth={380}
            defaultValue={product}
            initialSelectedItems={product ? [typeof product === 'object' ? product.id : product] : undefined}
            version='offer-form'
            onChange={handleSelectChange}
          />
        </ProductPicker>
      </LineWrapper>
      {(mode === 'edit' || mode === 'view') && product === undefined &&
            <>
                <StyledError name={'name'} errors={'The selected product is not available'}/>
            </>
        }
    </OfferInfoWrapper>
  );
};
