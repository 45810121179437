import { createTheme } from '@mui/material/styles';
import { CalendarIcon } from '@mui/x-date-pickers';

const redColorString = 'rgba(253, 242, 242, 1)';

export const newGlobalTheme = {
  name: 'New Global Theme - UI Redesign',
  colors: {
    global: {
      background: '#FFF',
      backdrop: 'rgba(0, 0, 0, 0.4)',
      error: '#DB0006',
      success: '#24A148',
      warning: '#F99B58',
      border: '#C4C5C8',
      inputPrimary: '#DB0006',
      inputDisabled: '#DDDEE1',
      borderColorboxes:'#6F6F6F',
      brandMain: '#FFBC0D',
      brandBackdrop: 'rgba(255, 204, 5, 0.15)',
      headerBackground: '#FFFFFF',
      imageBackground: '#F5F5F6',
      selectedBackground: '#FFFFFF',
      selectBackground: '#F1F2F3',
      boxShadow: '0 8px 25px 0 rgba(0, 0, 0, 0.22)',
      tabNavigation: '#1F5CF6',
      primaryColor: "#F6BF35",
      secondaryColor: "#DB0006",
      primaryButtonColor: "#DB0006",
      primaryTextColor: "#292929",
      secondaryTextColor: "#1D6CA9",
      errorTextColor: "#DB0006",
      textfieldBackgroundColor: "#F7F7F7",
      tileBackground: "#F5F5F5",
      inputDisabledSlide:'#ADADAD',
      textSubheading:'#006BAE',
      borderColor:"#D6D6D6",
      selectedBorderColor:'#F1C40F',
      disabledGold:"#F7E1A4",
      checkboxBorderColor:"#6F6F6F",
    },
    text: {
      primary: '#292929',
      disabled: '#9D9EA1',
      secondary: '#1D6CA9',
      white: '#FFF',
      backgroundColor: '#F7F7F7',
      backgroundColorRGB: 'rgb(247, 247, 247)',
      input: '#5E5E5E',
      error: '#D90007',
      disabledSave: '#6F6F6F',
      nonhighlighted:'#ADADAD',
    },
    tabStrip: {
      backgroundHover: 'rgba(0, 0, 0, 0.03)',
    },
    toggle: {
      disabled: '#ADADAD'
    },
    button: {
      primary: '#DB0006',
      secondary: '#FFBC0D',
      tertiary: '#FFF',
      hover: '#AC0E12',
      disabled: '#FFA4A6',
      border: {
        primary: '#292929',
        secondary: '#C08B00',
      },
      download: {
        border: '#959595',
      },
      new: {
        primary: '#FFBC0D',
        border: '#C08B00',
      },
      approve: {
        primary: 'rgba(36, 161, 72, 0.3)',
        disabled: 'rgba(36, 161, 72, 0.15)',
        hover: 'rgba(36, 161, 72, 0.7)',
      },
      reject: {
        primary: redColorString,
        hover: 'rgba(218, 30, 40, 0.7)',
      },
      revoke: {
        primary: 'rgba(249, 155, 88, 0.5)',
        hover: 'rgba(249, 155, 88, 0.7)',
      },
      disabledSave: '#F7E1A4',
    },
    ball:{
      offBg:'#FFFFFF',
    },
    checkbox: {
      iconColor: '#FFF',
      uncheckedBg: '#FFF',
      disabledCheckedBg: 'rgba(31, 92, 246, 0.5)',
    },
    notifications: {
      primary: {
        natural: '#9D9EA1',
        info: '#1F5CF6',
        warning: '#F99B58',
        success: '#24A148',
        error: '#DA1E28',
      },
      background: {
        natural: '#F1F2F3',
        info: '#E9EFFF',
        warning: '#FEEDE2',
        success: '#E4F3E9',
        error: '#FCE9EA',
      },
      banner:{
        primary: {
          natural: '#9D9EA1',
          info: '#006BAE',
          warning: '#F99B58',
          success: '#264F36',
          error: '#D90007',
        },
        background: {
          natural: '#F1F2F3',
          info: '#F2F9FE',
          warning: '#FEEDE2',
          success: '#F4FFF4',
          error: '#FDF2F2',
        },
      }
    },
    status: {
      draft: '#F9F9F9',
      pendingApproval: '#FFFEF5',
      approved: '#DCECDD',
      deployed: '#F2F9FE',
      active: '#95C598',
      expired: '#F9F9F9',
      revoked: '#FEF9F5',
      deploymentFailed: '#FDF2F2',
      rejected: '#FDF2F2',
      archived: '#D6D6D6',
      partiallyApproved: '#DCECDD',
      deploymentPending: '#DCECDD',
    },
    statusBorder: {
      draft: '#6F6F6F',
      pendingApproval: '#FFBC0D',
      approved: '#67A881',
      deployed: '#006BAE',
      active: '#264F36',
      expired: '#6F6F6F',
      revoked: '#EF8848',
      deploymentFailed: '#D90007',
      rejected: '#D90007',
      archived: '#6F6F6F',
      partiallyApproved: '#67A881',
      deploymentPending: '#67A881',
    },
    dashboard: {
      green: 'rgba(244, 247, 245, 1)',
      red: redColorString,
      yellow: 'rgba(255, 249, 245, 1)',
      blue: 'rgba(31, 92, 246, 0.2)',
      grey: 'rgba(245, 245, 245, 1)',
      orange: 'rgba(255, 249, 245, 1)',
      border : {
        green: 'rgba(31, 100, 55, 1)',
        red: 'rgba(219, 0, 7, 1)',
        yellow: 'rgba(246, 191, 53, 1)',
        blue: 'rgba(31, 92, 246, 0.2)',
        grey: 'rgba(245, 245, 245, 1)',
        orange: 'rgba(254, 130, 52, 1)',
        darkGrey: 'rgba(197, 197, 197)',
      }
    },
    pageHeader: {
      border: 'rgba(241, 242, 243)',
    },
    events: {
      local: '#c3baea',
      national: '#8fe0fa',
    },
  },
  ...createTheme(),
};

export type newAppTheme = typeof newGlobalTheme;