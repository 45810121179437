import styled from '@emotion/styled';
import { StyledWithThemeProps } from 'utils/types';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';
import OfferModal from 'components/shared/modal/OfferModal';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { ButtonContained, ButtonText, NewButtonContained, NewButtonText } from 'components/shared/button';

export const StyledModal = styled(OfferModal)`
  height: 580px;
  justify-content: center;
`;

export const StyledImpactTablesContainer = styled.div``;

export const StyledImpactTables = styled.div`
  display: flex;
  width: 100%;
`;

export const UsageFormContainer = styled.div<StyledWithThemeProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: auto;
  max-width: 640px;
  padding: 0px 36px;
  div[role='alert'] {
    margin-top: 42px;
    color: ${newGlobalTheme.colors.text.error} !important;
  }
`;

export const UsageTemplateSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: ${newGlobalTheme.colors.text.primary};
`;

export const UsageButtonsContainer = styled.div`
  display: flex;
  max-width: 640px;
  justify-content: space-between;
  padding: 36px 36px 0px 36px;
`;

export const UsageSelectbox = styled(NewSelectbox)`
  width: 100%;
`;

export const UsageStyledSpan = styled.span`
  font-family: Speedee;
  font-size: 14px;
  color: ${newGlobalTheme.colors.text.primary};
`;

export const CancelButtonNoBorder = styled(NewButtonText)`
  padding: 8px 12px 8px 0px;  
  font-family: Speedee;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.42px;
`

export const DownloadUsageButton = styled(NewButtonContained)`
  color: ${({ theme }) => newGlobalTheme.colors.text.primary};
  background-color: ${newGlobalTheme.colors.global.background};
  height: 32px;
  font-size:14px;
  border: 1px solid #6f6f6f;
  border-radius: 5px;
  :hover {
    background-color: inherit;
  }
`;