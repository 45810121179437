import React, { useMemo } from 'react';
import { store } from 'app/store';
import { Modals, openModal } from 'app/slices/modals';
import { FormMode } from 'utils/types';
import { OfferTemplates } from 'utils/types/offers';
import { marketConfig } from 'app/slices/config';
import { useSelector } from 'react-redux';
import { NoImageText } from 'pages/offers/offerManagement/Offers.style';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { getOfferStatus } from 'utils/offer';
import { Tags } from 'components/shared/tags/Tags';
import { archiveOffer, onOfferArchive } from '../offerActions/OfferActions';
import {
  OffersGridItem,
  OffersGridItemActions,
  OffersGridItemBackdrop,
  OffersGridItemImage,
  OffersGridItemImageContainer,
  OffersGridItemTemplate,
  StyledButtonText,
  StyledIcon,
  StyledStatusLabel,
  StyledView,
  StyledArchiveIcon,
  OfferNameWrapper,
  StyledAddIcon,
  StyledRemoveIcon,
  OffersGridItemTop,
  OffersGridItemBottom,
  OffersGridItemBottomHeader,
  OfferSubTitleWrapper,
  OfferIDWrapper,
  OffersGridItemBottomFooter,
  OffersViewDetailContainer,
  OffersStatusContainer,
  StyledViewDetailText,
  StyledSeprator,
  NewOffersGridItemBackdrop,
  NewOffersGridItemTop,
  NewOffersGridItem,
  NewOffersGridItemActions,
  NewOffersGridItemImageContainer,
  NewOffersGridItemImage,
  NewOffersGridItemTemplate,
} from './OfferItem.style';
import { OfferItemProps } from './OfferItem.consts';
import { getOfferById } from 'utils/api/offers';
import NewLockIcon from 'pages/shared/lockIcon/NewLockIcon';
import { OfferSource } from '../../Offers.const';
import { handleKeyPress } from 'utils/keyPress';


const OfferItem = ({
  offer,
  isSelectMode = false,
  isSelected,
  onAddClick,
  onRemoveClick,
  onClick,
  onViewClick,
  className,
  setIsOfferArchieving,
  offerSource,
}: OfferItemProps) => {
  const { config } = useSelector(marketConfig);
  const id = useMemo(() => Math.random().toString(8), [offer.updatedAt]);
  const primaryTranslation = offer?.versions[0]?.translationsMap[config.primaryLanguage];

  const onViewOfferClicked = async () => {
    const { data } = await getOfferById(offer.id, offerSource);
    const offerData = data.getOffer;
    store.dispatch(
      openModal({
        modal: Modals.OfferModal,
        props: { offer: offerData, offerSource: offerSource },
        data: { mode: FormMode.View, offerSource: offerSource },
      }),
    );
  };

  const onEditOfferClicked = async () => {
    const { data } = await getOfferById(offer.id, offerSource);
    const offerData = data.getOffer;
    store.dispatch(
      openModal({
        modal: Modals.OfferModal,
        props: { offer: offerData, mode: FormMode.Edit, offerSource: offerSource },
        data: { mode: FormMode.Edit, offerSource: offerSource },
      }),
    );
  };

  const onOfferClick = () => {
    // eslint-disable-next-line no-unused-expressions
    onClick ? onClick() : onViewOfferClicked();
  };

  return (
    <NewOffersGridItem className={className} data-automation-id="offer-item">
      <NewOffersGridItemTop onClick={onOfferClick} role="button" tabIndex={0} >
        <NewOffersGridItemBackdrop>
          <NewOffersGridItemActions>
            
            {isSelectMode ? (
              <>
                <StyledButtonText onClick={!onViewClick ? onViewOfferClicked : onViewClick} data-title="View" >
                  <StyledView name="view" />
                </StyledButtonText>
                {isSelected ? (
                  <StyledButtonText onClick={onRemoveClick} data-title="Remove">
                    <StyledRemoveIcon name="minusVector" />
                  </StyledButtonText>
                ) : (
                  <StyledButtonText onClick={onAddClick} data-title="Add">
                    <StyledAddIcon name="plusVector" />
                  </StyledButtonText>
                )}
              </>
            ) : (
              <>
                {!offer.isArchive && (
                  <>
                    {offer.offerSource === OfferSource.VCE ? (
                      <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
                        <StyledButtonText 
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onEditOfferClicked();
                          }}
                          data-title="Edit"
                        >
                          <StyledIcon name="offerEdit" staticImport={true}/>
                        </StyledButtonText>
                        <StyledButtonText
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            return offer.isArchive
                              ? archiveOffer(offer, false)
                              : onOfferArchive(offer, setIsOfferArchieving);
                          }}
                          data-title={offer.isArchive ? 'Unarchive' : 'Archive'}
                        >
                          <StyledArchiveIcon  name={offer.isArchive ? 'unarchive' : 'newArchive'} staticImport={true} />
                        </StyledButtonText>
                      </RoleGuard>
                    ) : (
                      <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin]}>
                        <StyledButtonText
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onEditOfferClicked();
                          }}
                          data-title="Edit"
                        >
                          <StyledIcon name="edit" staticImport={true} />
                        </StyledButtonText>
                      </RoleGuard>
                    )}
                  </>
                )}
              </>
            )}
          </NewOffersGridItemActions>
          <Tags id={offer.id} tags={offer.tags} limit={5} />
        </NewOffersGridItemBackdrop>
        
        <NewOffersGridItemImageContainer>
          {primaryTranslation?.image ? (
            <NewOffersGridItemImage
              src={`${primaryTranslation?.image?.file}?${id}`}
              alt={`${primaryTranslation?.image?.name}`}
              loading="lazy"
              crossOrigin="anonymous"
            />
          ) : (
            <NoImageText>No Image Defined</NoImageText>
          )}
        </NewOffersGridItemImageContainer>
      </NewOffersGridItemTop>
      <StyledSeprator></StyledSeprator>
      <OffersGridItemBottom>
        <OffersGridItemBottomHeader>
          <OfferNameWrapper>
            <span tabIndex={0} title={primaryTranslation?.title}>{primaryTranslation?.title}</span>
            {offer.isLocked && <NewLockIcon dataFor={'offer-lock-icon'}  />}
          </OfferNameWrapper>
            <OfferIDWrapper>
            <span tabIndex={0}>{`ID ${offer?.id}`}</span>
             </OfferIDWrapper>
          <NewOffersGridItemTemplate>
            {OfferTemplates[offer.templateType as keyof typeof OfferTemplates]?.name}
          </NewOffersGridItemTemplate>
          <OfferSubTitleWrapper>
            <span tabIndex={0}  title={primaryTranslation?.subtitle}>{primaryTranslation?.subtitle}</span>
          </OfferSubTitleWrapper>
        </OffersGridItemBottomHeader>
        <OffersGridItemBottomFooter>
          <OffersStatusContainer tabIndex={0}>
            <StyledStatusLabel type={getOfferStatus(offer)} />
            </OffersStatusContainer>
          <OffersViewDetailContainer tabIndex={0} onKeyDown={(event) => handleKeyPress(event, () => onOfferClick())}  >
            <StyledViewDetailText onClick={!onViewClick ? onViewOfferClicked : onViewClick} >
              View Details
            </StyledViewDetailText>
          </OffersViewDetailContainer>
        </OffersGridItemBottomFooter>
      </OffersGridItemBottom>
    </NewOffersGridItem>
  );
};

export default OfferItem;
